import { useLocation } from "@remix-run/react";
import { useEffect } from "react";
import * as gtag from "../utils/gtags.client";

export const useGA = (gaTrackingId: string) => {
  const location = useLocation();
  useEffect(() => {
    if (gaTrackingId?.length) {
      gtag.pageview(location.pathname + location.search, gaTrackingId);
    }
  }, [, location, gaTrackingId]);
};
