import { useFetcher } from "@remix-run/react";

export const CookieConsent = () => {
  const fetcher = useFetcher();

  const allConsentGranted = () => {
    if (window.gtag) {
      window.gtag("consent", "update", {
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        ad_storage: 'granted',
        analytics_storage: 'granted'
      });
    }
  }
  return (
    <div className="fixed bottom-4 left-0 right-0 mx-auto flex w-fit max-w-[400px] flex-col items-center justify-center bg-white p-4">
      <div className="text-xl font-semibold">🍪</div>
      <div className="mt-4 text-center">
        Við notum vafrakökur til að safna og greina upplýsingar um notkun, til
        að geta notað samfélagsmiðlalausnir og til að bæta þjónustuna okkar.
      </div>
      <fetcher.Form>
        <div
          className="primary mt-4 cursor-pointer bg-white px-8 py-4 text-center font-semibold text-black"
          onClick={() => {
            fetcher.submit(
              { intent: "reject" },
              {
                method: "post",
                action: "/cookie-consent",
              },
            );
          }}
        >
          Hafna
        </div>
        <div
          className="my-2 cursor-pointer bg-[#3C5043] px-8 py-4 font-semibold text-white hover:opacity-80"
          onClick={() => {
            allConsentGranted();
            fetcher.submit(
              { intent: "accept" },
              {
                method: "post",
                action: "/cookie-consent",
              },
            );
          }}
        >
          Samþykkja
        </div>
      </fetcher.Form>
    </div>
  );
};
